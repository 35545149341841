<template>
	<div class="shadow-2 p-3 h-full flex flex-column surface-card" align="left" style="border-radius: 6px;padding: 0px;">
		<div v-if='language'>
			<div class="text-900 font-medium text-xl mb-2">
				Pricing
				<a :href=video.en target="_blank">
					<Tag class="mr-2" severity="info" style="margin-left: 20px;" value="Latest Tutorial video"></Tag>
				</a>
<!-- 				<a href="https://www.libertynlp.com/index.php/2022/06/24/how-to-import-and-use-pdf-js-in-a-new-project/"
					target="_blank">
					<Tag class="mr-2" severity="success" style="margin-left: 20px;" value="Usage"></Tag>
				</a> -->
			</div>
			<div class="text-600" style="font-size: 12px;">Contact <span
					style="font-weight: 700;">weichaoxu1998@gmail.com</span> for more details.</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<div class="flex align-items-center">
				<span class="font-bold text-2xl text-900">{{price.en.source_code}}</span>
				<span class="ml-2 font-medium text-600">source code</span>
			</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<div title="Std API: read/write annotations" class="flex align-items-center">
				<span class="font-bold text-2xl text-900">{{price.en.encrypted_code}}</span>
				<span class="ml-2 font-medium text-600">encrypted code</span>
			</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<div title="Std API: read/write annotations" style="justify-content: space-between;" class="flex align-items-center">
				<div>
					<span class="font-bold text-2xl text-900">{{price.en.per_month}}</span>
					<span class="ml-2 font-medium text-600">per month</span>
				</div>
				<a title='Apply for monthly licence'
					href="https://github.com/ChaoxuWei/pdfjs-annotation" target="_blank"><Button style="width: 100%;padding: 5px;"
						label="Apply monthly licence" icon="pi pi-arrow-right"  iconPos="right"
						class="p-button-danger" /></a>
			</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<ul class="list-none p-0 m-0 flex-grow-1">
				<li class="flex align-items-center mb-3">
					<i class="pi pi-check-circle text-green-500 mr-2"></i>
					<span>free update(6 months), tech support</span>
				</li>
				<li class="flex align-items-center mb-3">
					<i class="pi pi-check-circle text-green-500 mr-2"></i>
					<span>fewer features, more discounts</span>
				</li>
			</ul>
			<hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
			<div class="text-600" style="font-size: 12px;margin-bottom: 10px;">*All rights reserved, secondary
				distribution and sale are prohibited.</div>
		</div>
		<div v-if='!language'>
			<div class="text-900 font-medium text-xl mb-2">
				价格
				<a :href=video.zh_cn  target="_blank">
					<Tag class="mr-2" severity="info" style="margin-left: 20px;" value="最新视频教程"></Tag>
				</a>
<!-- 				<a href="https://www.libertynlp.com/index.php/2022/06/24/how-to-import-and-use-pdf-js-in-a-new-project/"
					target="_blank">
					<Tag class="mr-2" severity="success" style="margin-left: 20px;" value="使用教程"></Tag>
				</a> -->
			</div>
			<div class="text-600" style="font-size: 12px;">添加微信或发送邮件至<span
					style="font-weight: 700;">weichaoxu1998@gmail.com</span>详询</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<div class="flex align-items-center">
				<span class="font-bold text-2xl text-900">{{price.zh_cn.source_code}}</span>
				<span class="ml-2 font-medium text-600">源代码</span>
			</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<div class="flex align-items-center">
				<span class="font-bold text-2xl text-900">{{price.zh_cn.encrypted_code}}</span>
				<span class="ml-2 font-medium text-600">加密代码</span>
			</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<div class="flex align-items-center" style="justify-content: space-between;">
				<div>
					<span class="font-bold text-2xl text-900">{{price.zh_cn.per_month}}</span>
					<span class="ml-2 font-medium text-600">每月</span>
				</div>
				<a title='了解月付详情和申请月付许可'
					href="https://github.com/ChaoxuWei/pdfjs-annotation" target="_blank"><Button style="width: 100%;padding: 5px;"
						label="了解月付规则和申请月付许可" icon="pi pi-arrow-right"  iconPos="right"
						class="p-button-danger" /></a>
			</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<ul class="list-none p-0 m-0 flex-grow-1">
				<li class="flex align-items-center mb-3">
					<i class="pi pi-check-circle text-green-500 mr-2"></i>
					<span>免费更新(6个月)，技术支持</span>
				</li>
				<li class="flex align-items-center mb-3">
					<i class="pi pi-check-circle text-green-500 mr-2"></i>
					<span>减选功能，价格可以优惠</span>
				</li>
			</ul>
			<hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
			<div class="text-600" style="font-size: 12px;margin-bottom: 10px;">*版权所有：保留所有权利，禁止二次售卖</div>
		</div>
		<div v-if='false'>
			<a :href="pay_src" target="_blank"><Button title='pay me with PayPal and contact me to get code'
					label="Buy Now" icon="pi pi-paypal" style="width: 55%;float: left;"></Button></a>
			<Button style="width: 40%;float: right;" @click="toggle_wechat_pay" title='使用中国大陆支付方式,支持公对公转账' label="中国大陆支付"
				class="p-button-success mr-2 mb-2"></Button>
			<OverlayPanel ref="wechat_pay" appendTo="body" :showCloseIcon="true">
				<div style="width: 400px;" align="center">
					<h5>支持签订合同公对公转账和开具发票 (姓: 韦)</h5>
					<!-- <Button label="标准API ￥550元/月/域名" style="width: 350px;" class="p-button-info mb-3" /> -->
					<Button :label='"加密代码 "+price.zh_cn.encrypted_code' style="width: 350px;" class="p-button-success mb-3" />
					<Button :label='"源代码 "+price.zh_cn.source_code' style="width: 350px;" class="p-button-info mb-3" />
					<!-- <Button label="增强API ￥20500元/终生/3域名(无免费更新)" style="width: 350px;" class="p-button-success mb-3" /> -->
					<img src="images/wechat_pay.jpg" style='width: 350px;' alt="" />
				</div>
			</OverlayPanel>
		</div>
	</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	import ReadFileService from '../service/ReadFileService.js';
	export default {
		name: '',
		components: {

		},
		data() {
			return {
				pay_src: 'https://paypal.me/weichaoxu?country.x=C2&locale.x=zh_XC',
				price: {
					"en": {
						"source_code": "$ ???? USD",
						"encrypted_code": "$ ???? USD",
						"per_month": "$ ??? USD"
					},
					"zh_cn": {
						"source_code": "￥ ????? 元",
						"encrypted_code": "￥ ????? 元",
						"per_month": "$ ???? 元"
					}
				},
				video:{
					"en":"???????",
					"zh_cn":"?????????"
				},
			}
		},
		created() {
			this.ReadFileService = new ReadFileService();
		},
		mounted() {
			this.ReadFileService.getUpdateInfo().then(data => {
				this.price=data.price;
				this.video=data.video;
			});
		},
		methods: {
			toggle_wechat_pay(event) {
				this.$refs.wechat_pay.toggle(event);
			},
		},
		computed:{
			language() {
				if (this.$appState.language=='en'){
					return true;
				}else{
					return false;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
</style>
